:root {
  --primary: #fff;
}

.btn {
  padding: 8px 2px; 
  border-radius: 20px;
  outline: none;
  border: none;
  cursor: pointer; 

}

.btn--primary { 
  background-color: var(--primary);
  color: #242424; 
  border: 1px solid var(--primary);
  border-radius: 20px;
}

.btn--outline {
  background-color: transparent;
  color: #fff;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  border-radius: 20px;
}

.btn--medium {
  padding: 8px 20px; 
  font-size: 20px;
  
}
.btn--large {
  padding: 12px 26px; 
  font-size: 20px;

}

.btn--medium:hover, .btn--large:hover { 
  background: #fccd56; 
  color: #242424;
  transition: all 0.3s ease-out;
}
.about-me-container {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;


}

.about-me-container > h1 {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 2em;
  font-weight: bold;
  color: #2d2e32;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

h3 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #2d2e32;

}

.statement {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  margin-bottom: 10px;
  width: 100%;

}

.statement-wrapper {
  width: 50%;
  line-height: 1.5em;
}
